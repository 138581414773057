<template>
    <div>
        <b-container v-if="!getAgenciesPending">
            <div class="mb-3">
                <Spinner size="medium" line-fg-color="#e91e63" />
            </div>
        </b-container>
        <b-container v-else>
            <section v-if="getFilteredAgencies.length != 0 && getAgenciesPending" class="agencies pt-0">
                <div class="sector pb-0 mb-0">
                    <h2 class="main-title">Latest Agencies</h2>
                    <p class="main-subtitle">Check out the latest digital agencies</p>
                    <b-row>
                        <b-col v-for="agency in orderBy(getFilteredAgencies, 'id', -1)" :key="agency.id" cols="12" md="6" lg="6" xl="4">
                            <div class="agencies__element">
                                <div v-if="agency.stability" class="agencies__stats">
                                    <span class="agencies__star-tooltip" v-b-tooltip title="Statistics included"><simple-line-icons icon="chart" size="small" color="#504e70" /></span>
                                </div>
                                <div v-if="agency.leader" class="agencies__leader">
                                    <span class="agencies__star-tooltip" v-b-tooltip title="Market leader"><simple-line-icons icon="diamond" size="small" color="#504e70" /></span>
                                </div>
                                <div v-if="agency.ribbon" class="custom-ribbon custom-ribbon--right">
                                    <span class="custom-ribbon__text custom-ribbon__text--blue">Featured</span>
                                </div>
                                <div class="agencies__image" :style="{ backgroundImage: 'url(' + agency.url + ')' }"></div>
                                <div class="agencies__content">
                                    <h2 class="agencies__title" v-line-clamp:24="2">{{ agency.name }}</h2>
                                    <p v-html="agency.desc" class="agencies__text" v-line-clamp:24="3"></p>
                                    <div v-if="agency.rating" class="agencies__rating agencies__rating--active">
                                        <b-row>
                                            <b-col cols="12">
                                                <span :id="agency.id" class="agencies__star-tooltip"><star-rating :star-size="18" border-color="transparent" :show-rating="false" :rating="agency.rating" :increment="0.01" :read-only="true"></star-rating></span>
                                            </b-col>
                                            <b-tooltip :target="agency.id">
                                                Rating: {{ agency.rating }}
                                            </b-tooltip>
                                        </b-row>
                                    </div>
                                    <div v-else class="agencies__rating"></div>
                                    <b-row>
                                        <b-col cols="12">
                                            <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Minimum project size"><simple-line-icons icon="tag" size="small" color="#504e70" /></span>{{ agency.project.text }}</span>
                                        </b-col>
                                        <b-col cols="12">
                                            <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Employees"><simple-line-icons icon="people" size="small" color="#504e70" /></span>{{ agency.employees.text }}</span>
                                        </b-col>
                                        <b-col cols="12">
                                            <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ agency.city }}, {{ agency.country.text }}</span>
                                        </b-col>
                                        <b-col cols="12">
                                            <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Founded"><simple-line-icons icon="flag" size="small" color="#504e70" /></span>{{ agency.founded }}</span>
                                        </b-col>
                                        <b-col v-if="agency.email" cols="12">
                                            <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="E-mail"><simple-line-icons icon="envelope" size="small" color="#504e70" /></span><a class="agencies__email-link" :href="'mailto:'+ agency.email">{{ agency.email }}</a></span>
                                        </b-col>
                                        <b-col v-if="agency.phone" cols="12">
                                            <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Phone number"><simple-line-icons icon="phone" size="small" color="#504e70" /></span>{{ agency.phone }}</span>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="agencies__details">
                                    <b-row>
                                        <b-col cols="12" sm="6">
                                            <a class="agencies__button agencies__button--website" :href="agency.website" target="_blank"><simple-line-icons icon="globe" size="small" color="#504e70" />Visit website</a>
                                        </b-col>
                                        <b-col cols="12" sm="6">
                                            <button @click="redirectToLatest(agency.id)" class="agencies__button agencies__button--profile"><simple-line-icons icon="grid" size="small" color="#fff" />View profile</button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center mb-4">
                        <router-link to="/agencies" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View more</router-link>
                    </b-row>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import StarRating from 'vue-star-rating'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import router from '../router/router'
import Vue2Filters from 'vue2-filters'
export default {
  components: {
    SimpleLineIcons,
    StarRating,
    Spinner
  },
  mixins: [Vue2Filters.mixin],
  computed: {
    ...mapGetters([
      'getLatestAgencies',
      'getAgenciesPending'
    ]),
    getFilteredAgencies () {
      var agencies = Object.values(this.getLatestAgencies).filter((agency) => {
        return !agency.id.includes(this.$route.params.id)
      })

      return agencies
    }
  },
  methods: {
    redirectToLatest (routeId) {
      router.push({ name: 'agency', params: { id: routeId } })
      this.$store.dispatch('getAgency', { id: routeId })
    }
  },
  beforeCreate () {
    this.$store.dispatch('getLatestAgencies')
  }
}
</script>
